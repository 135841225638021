
@keyframes fadeIn {
  0% { opacity: 0 }
  100% { opacity: 1 }
}

@keyframes fadeOut {
  0% { opacity: 1 }
  100% { opacity: 0 }
}

body { overflow: hidden }

.fade-in-main {
  animation: fadeIn 0.8s;
  animation-timing-function: linear;
}

.mobiius-top-container {
  background-color: black;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100vh;
  min-width: 100vw;
  z-index: 1;
}

.skip-button-container {
  position: absolute;
  width: 85%;
  height: 80px;
  bottom: 0;
  display: flex;
  justify-content: center;
}

.skip-button-container > img {
  object-fit: contain;
  width: 80px;
  cursor: pointer;
}

.fade-out-image { opacity: 0 }

.main-content-container {
  max-width: 1400px;
  max-height: 1000px;
  margin: 0 auto;
  position: relative;
}

.mobiius-logo {
  width: 450px;
  object-fit: contain;
  transition: all .2s ease-in-out;
  cursor: pointer;
  z-index: 200;
  margin-bottom: 40px;
}

.archives-logo {
  width: 300px;
  transition: none;
  margin-left: 30vw;
  position: absolute;
  cursor: pointer;
  z-index: 200;
  padding-top: 80px;
  object-fit: contain;
}

.archives-logo:hover {
  transform: none !important;
}

.archives-back-button {
  position: absolute;
  padding-top: 130px;
  margin-left: 3vw;
  width: 140px;
  transition: all .2s ease-in-out;
  cursor: pointer;
}

.archives-back-button:hover {
  transform: scale(1.03);
}

.mobiius-logo:hover {
  transform: scale(1.03);
}

.mobiius-side-logo {
  height: 900px;
  position: absolute;
  right: 1vw;
  object-fit: contain;
  padding-top: 80px;
  justify-self: end;
}

.email-entry-container {
  padding-top: 200px;
  margin-right: 400px;
  padding-right: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mobiius-success-message {
  object-fit: contain;
  max-width: none;
  width: 400px;
  height: 298px;
}

.email-copy-image {
  object-fit: contain;
  width: 100%;
  max-width: 250px;
  height: 120px;
  padding-bottom: 30px;
}

.email-entry-container > form {
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.email-input {
  background-color: #C507A8;
  width: 280px;
  height: 26px;
  border-radius: 2px;
  border: none;
  font-size: 14pt;
  padding: 5px;
}

.email-input::placeholder {
  color: black;
  text-align: center;
}

.submit-button {
  width: 80px;
  height: 26px;
  padding: 3px;
  background-color: transparent;
  font-size: 13pt;
  border-radius: 2px;
  border: none;
  margin-top: 30px;
  box-sizing: content-box;
  cursor: pointer;
  transition: all .2s ease-in-out;
}

.submit-button:hover {
  transform: scale(1.05);
}

.submit-button > img { width: 100% }

.mobiius-socials {
  display: flex;
  width: 100%;
  max-width: 400px;
  justify-content: space-evenly;
}

.mobiius-socials img {
  height: 40px;
  width: 40px;
  transition: all .2s ease-in-out;
}

.mobiius-socials img:hover {
  transform: scale(1.05);
}

.mobiius-archives-container {
  height: 100%;
}

.archives-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding-top: 250px;
  padding: 250px 0 0 4vw;
}

.archives-grid > img {
  width: 150px;
  transition: all .2s ease-in-out;
  cursor: pointer;
}

.archives-grid > img:hover {
  transform: scale(1.03);
}

.archive-text-container {
  position: absolute;
  top: 250px;
  margin: 0px 5vw 50px;
  padding: 0 10px;
  height: 500px;
  overflow: scroll;
  background-color: rgba(0, 0, 0, 0.5);
}

.archive-text {
  color: white;
  max-width: 700px;
  padding: 10px 0;
  font-size: 13pt;
  z-index: 500;
  position: relative;
}

.archive-text:last-of-type {
  padding-bottom: 200px;
}

@media only screen and (max-width: 1700px) {
  .mobiius-logo { width: 300px }
  .mobiius-side-logo { height: 600px }
  .email-entry-container { 
    padding-top: 140px; 
    width: 400px;
    margin: 0 auto;
  }
  .email-copy-image { max-width: 200px }
  .archive-text-container { top: 200px }
}

@media only screen and (max-width: 1000px) {
  .archive-text-container { max-width: 500px }
}

@media only screen and (max-width: 900px) {
  .mobiius-logo { width: 300px; padding-top: 40px; }
  .archives-logo { padding-top: 14px }
  .archives-back-button { padding-top: 69px; width: 100px }
  .archives-grid { padding-top: 150px }
  .archive-text-container { top: 150px }
  .email-entry-container { 
    z-index: 1;
    position: relative;
    width: 60%;
    padding: 100px 120px 0 0;
  }
}

@media only screen and (max-width: 750px) {
  .mobiius-side-logo { display: none }
  .archives-logo { margin-left: 36vw }
  .email-entry-container { width: 100% }
  .skip-button-container > img { width: 60px }
  .archive-text-container { 
    width: 90%; 
    max-width: none;
    height: 400px;
  }
}

@media only screen and (max-width: 600px) {
  .mobiius-logo { padding-top: 0px }
  .archives-logo { margin-left: 10vw }
  .archives-back-button { top: 520px; margin-left: 3vw }
  .archives-grid { grid-template-columns: repeat(2, auto) }
}

@media only screen and (max-width: 400px) {
  .archives-logo { margin-left: 10vw }
  .mobiius-success-message { width: 95%; margin: 0 auto }
}
